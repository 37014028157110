
export default {
  name: 'HomePageHeader',

  props: {
    api: {
      type: Object,
      default: null,
    },
  },

  computed: {
    text () {
      return this.api.children.find(({ componentType }) => componentType === 'h1')?.data.text ?? ''
    },
  },
}
